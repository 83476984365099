var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col col-12 col-lg-9"},[_c('h1',[_vm._v("Requerimientos Minimos de Hardware y Software")]),_vm._m(0),_c('h2',{attrs:{"id":"hardware"}},[_vm._v("Hardware")]),_c('p',[_vm._v("Se requiere que se garantice contar con al menos 40% de recursos disponibles")]),_vm._m(1),_c('h2',{attrs:{"id":"impresion"}},[_vm._v("Impresión de Stickers")]),_c('p',[_vm._v("Modelo de Impresora TSC 200")]),_vm._m(2),_c('h2',{attrs:{"id":"conectividad"}},[_vm._v("Conectividad")]),_vm._m(3),_c('ul',{staticClass:"browser-default"},[_vm._m(4),_vm._m(5),_c('li',{staticClass:"browser-default"},[_c('i',[_vm._v("Acceso a Aplicaciones y Servicios")]),_vm._v(": Acceso directo desde el PC Cliente al Servidor Web y de Aplicaciones (teniendo configurados los servicios intermediarios, si existen tales como proxy, antivirus, firewall, entre otros, de forma tal que no causen limitantes o condiciones adversas) a las siguientes direcciones en el puerto 443 (HTTPS): "+_vm._s(_vm.MainURL)+" y "+_vm._s(_vm.StaticURL)+" ")]),_vm._m(6)]),_c('h2',{attrs:{"id":"softwarebase"}},[_vm._v("Software Base")]),_c('p',[_vm._v("Tener el software adecuadamente licenciado, actualizado y cumplir con:")]),_vm._m(7),_vm._m(8),_c('h2',{attrs:{"id":"aplicacionesweb"}},[_vm._v("Aplicaciones Web")]),_vm._v(" Navegador Web: "),_vm._m(9),_vm._v(" Software de Edición: "),_vm._m(10),_c('h2',{attrs:{"id":"aplicacionesescritorio"}},[_vm._v("Aplicaciones de Escritorio")]),_vm._v(" Framework: "),_vm._m(11)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v("A continuación se listan los requerimientos mínimos para: "),_c('ul',[_c('li',[_c('a',{attrs:{"href":"#hardware"}},[_vm._v("Hardware")])]),_c('li',[_c('a',{attrs:{"href":"#impresion"}},[_vm._v("Impresión de Stickers")])]),_c('li',[_c('a',{attrs:{"href":"#conectividad"}},[_vm._v("Conectividad")])]),_c('li',[_c('a',{attrs:{"href":"#softwarebase"}},[_vm._v("Software Base (nivel general)")])]),_c('li',[_c('a',{attrs:{"href":"#aplicacionesweb"}},[_vm._v("Aplicaciones Web")])]),_c('li',[_c('a',{attrs:{"href":"#aplicacionesescritorio"}},[_vm._v("Aplicaciones de Escritorio")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"browser-default"},[_c('li',{staticClass:"browser-default"},[_c('i',[_vm._v("Memoria RAM")]),_vm._v(": 4GB ")]),_c('li',{staticClass:"browser-default"},[_c('i',[_vm._v("Procesador")]),_vm._v(": 2Ghz (doble nucleo) ")]),_c('li',{staticClass:"browser-default"},[_c('i',[_vm._v("Espacio libre en Disco")]),_vm._v(": 50GB (recomendado que esa de estado solido - SSD) ")]),_c('li',{staticClass:"browser-default"},[_c('i',[_vm._v("Tarjeta de Red")]),_vm._v(": 100Mbps, recomendado 1Gbps ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"browser-default"},[_c('li',{staticClass:"browser-default"},[_c('a',{attrs:{"target":"_blank","href":"http://www.tscprinters.com/cms/upload/download_en/TE200_user_manual_E.pdf"}},[_vm._v("Manual")])]),_c('li',{staticClass:"browser-default"},[_c('a',{attrs:{"target":"_blank","href":"http://www.tscprinters.com/cms/upload/download_en/TE200_VA2.05_EZD.zip"}},[_vm._v("Controladores")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v(" Se requiere que se garantice contar con al menos 80% de recursos disponibles, "),_c('i',[_vm._v("aplica por cada PC conectado")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"browser-default"},[_c('i',[_vm._v("Acceso a Internet")]),_vm._v(": Canal mínimo de 10Mbps (mínimo 4Mbps tando de Subida como de Descarga) ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"browser-default"},[_c('i',[_vm._v("Tipo de Conexión")]),_vm._v(": Directa o via Proxy. Si es via proxy este debe soportar la versión 1.1 de HTTP ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"browser-default"},[_c('i',[_vm._v("Calidad de Servicio (QoS)")]),_vm._v(": Conectividad estable al Servidor Web y de Aplicaciones sobre el Canal de Comunicaciones para que el trafico de Aplicaciones tenga garantizado un margen de recursos y no se vea afectado por otro tipo de trafico ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"browser-default"},[_c('li',{staticClass:"browser-default"},[_c('a',{attrs:{"href":"http://windows.microsoft.com/en-us/windows/lifecycle","target":"_blank"}},[_vm._v("Ciclo de Vida de Aplicaciones de Microsoft")])]),_c('li',{staticClass:"browser-default"},[_c('a',{attrs:{"target":"_blank","href":"https://support.microsoft.com/en-us/lifecycle#;"}},[_vm._v("Anuncios de soporte de Microsoft")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"browser-default"},[_c('li',{staticClass:"browser-default"},[_vm._v(" Sistema Operativo: "),_c('a',{attrs:{"target":"_blank","href":"https://www.microsoft.com/en-us/software-download/windows10"}},[_vm._v("Microsoft Windows 10 Professional ")]),_vm._v(", recomendado "),_c('a',{attrs:{"target":"_blank","href":"https://www.microsoft.com/es-es/software-download/windows11"}},[_vm._v("Microsoft Windows 11 Professional ")]),_c('br'),_c('a',{attrs:{"target":"_blank","href":"https://docs.microsoft.com/en-us/lifecycle/products/windows-7"}},[_vm._v("Microsoft dejo de dar soporte a Windows 7")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"browser-default"},[_c('li',{staticClass:"browser-default"},[_c('a',{attrs:{"href":"https://www.google.com/chrome/browser/desktop/index.html","target":"_blank"}},[_vm._v("Google Chrome, versión 121.0.6167.140 o superior")])]),_c('li',{staticClass:"browser-default"},[_c('a',{attrs:{"href":"https://www.mozilla.org/es-ES/firefox/new/","target":"_blank"}},[_vm._v("Firefox, versión 122.0 o superior")])]),_c('li',{staticClass:"browser-default"},[_c('a',{attrs:{"href":"https://www.opera.com/download","target":"_blank"}},[_vm._v("Opera, versión 106.0.4998.66 o superior")])]),_c('li',{staticClass:"browser-default"},[_c('a',{attrs:{"href":"https://www.microsoft.com/en-us/edge","target":"_blank"}},[_vm._v("Microsoft Edge, versión 121.0.2277.98 o superior")]),_c('br'),_vm._v(" (NO soportado por Microsoft) Microsoft Internet Explorer "),_c('a',{attrs:{"href":"https://www.microsoft.com/en-us/windowsforbusiness/end-of-ie-support?SilentAuth=1","target":"_blank"}},[_vm._v("dejo de ser soportado por Microsoft")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"browser-default"},[_c('li',{staticClass:"browser-default"},[_c('i',[_vm._v("Editor de Documentos")]),_vm._v(": "),_c('a',{attrs:{"target":"_blank","href":"https://products.office.com/en-US/compare-all-microsoft-office-products?tab=2"}},[_vm._v("Microsoft Office")]),_vm._v(" Professional 2023, o superior ")]),_c('li',{staticClass:"browser-default"},[_c('i',[_vm._v("Visor de PDF")]),_vm._v(": "),_c('a',{attrs:{"href":"https://get.adobe.com/reader/"}},[_vm._v("Adobe Acrobat Reader")]),_vm._v(" XI o superior, o un lector compatible con PDF como Google Chrome, Opera o Firefox ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"https://dotnet.microsoft.com/download/dotnet/8.0","target":"_blank"}},[_vm._v("Microsoft .NET Runtime 8.0.6 o superior")])]),_c('li',[_c('a',{attrs:{"href":"https://dotnet.microsoft.com/download/dotnet/8.0","target":"_blank"}},[_vm._v("Microsoft .NET Desktop Runtime 8.0.6 o superior")])]),_c('li',[_c('a',{attrs:{"href":"https://go.microsoft.com/fwlink/?linkid=2088631","target":"_blank"}},[_vm._v("Microsoft .NET Framework 4.8 (para las aplicaciones de la edición clásica)")])])])
}]

export { render, staticRenderFns }