export default {
    name: "WebClientMinimalRequirements",
  
    data() {
      return {
        MainURL: Settings.MainURL,
        StaticURL: Settings.StaticURL
      };
    },
  
    methods: {
    },
  
    mounted() {
    }
  
  };